<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/home-hero.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="Save Your Real Funds" />

          <!--<base-body>
            Service for investing in the real economy<br />
            money should work, only in this case it does not disappear
          </base-body>-->

         <base-body>
            {{$ml.with('VueJS').get('slogan1')}}<br />
            {{$ml.with('VueJS').get('slogan2')}}
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn v-on:click="$router.push('/about')">
              {{$ml.with('VueJS').get('more')}}
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">{{$ml.with('VueJS').get('or')}}</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
              v-on:click="gotoMarket()"
            >
              {{$ml.with('VueJS').get('start')}}
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },
    methods:{
        gotoMarket(){
            window.open("https://waves.exchange/trading/spot/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK_USDT");
        }
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
